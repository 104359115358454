<template>
  <v-container fluid>
    <v-row
      align="center"
      no-gutters
      class="ml-0"
    >
      <v-col cols="12">
        <v-alert
          dense
          outlined
          type="info"
          color="blue"
          class="py-1 px-1"
        >
          Exibindo {{ feicoesExibidas | parseNumberToIntegerBR }} de um total de
          {{ totalFeicoes | parseNumberToIntegerBR }} feições. Máximo a serem
          plotadas: {{ maximoFeicoes | parseNumberToIntegerBR }} feições por
          layer.
        </v-alert>
      </v-col>
      <v-col cols="12">
        <div id="visualizar-mapa-preview-mapa"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VisualizarMapaMixins from './VisualizarMapaMixins';

export default {
  mixins: [VisualizarMapaMixins],

  data() {
    return {
      mapaId: 'visualizar-mapa-preview-mapa'
    };
  },

  computed: {
    layers() {
      return this.$store.getters.getCanvasConfigLayers;
    },
    maximoFeicoes() {
      return this.getMapConfigMaxFeatures ?? 5000;
    }
  }
};
</script>

<style>
#visualizar-mapa-preview-mapa {
  min-height: 420px;
  max-height: 420px;
  z-index: 0;
}

.mapa-info {
  padding: 6px 8px;
  font:
    14px/16px Arial,
    Helvetica,
    sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.mapa-info h4 {
  margin: 0 0 5px;
  color: #777;
}

.mapa-legend {
  line-height: 18px;
  color: #555;
  max-height: 177px !important;
  overflow-y: auto !important;
}

.mapa-legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}
</style>
