import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'getMapFeaturesStyles',
      'getMapFeatureColor',
      'getMapFeatureFillColor',
      'getMapFeatureWeight',
      'getMapFeatureOpacity',
      'getMapFeatureFillOpacity',
      'getMapFeatureRadius'
    ])
  }
};
