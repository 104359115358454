import L from 'leaflet';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import GeoServerService from '@/services/GeoServerService';
import mapFeaturesStylesMixins from '@/mixins/mapFeaturesStylesMixins';
import mapConfigMixins from '@/mixins/mapConfigMixins';
import geocoderMixins from '@/mixins/leaflet/geocoderMixins';
import browserPrintMixins from '@/mixins/leaflet/browserPrintMixins';
import scaleMixins from '@/mixins/leaflet/scaleMixins';
import drawMixins from '@/mixins/leaflet/drawMixins';
import filtersMixins from '@/mixins/geoserver/filtersMixins';
import dayjs from 'dayjs';
import '@/plugins/leaflet-icon-glyph-v2';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

export default {
  mixins: [
    mapFeaturesStylesMixins,
    mapConfigMixins,
    geocoderMixins,
    browserPrintMixins,
    scaleMixins,
    drawMixins,
    filtersMixins
  ],

  data() {
    return {
      mapaId: 'mapa',
      map: null,
      bboxString: null,
      layerControl: null,
      legendControl: null,
      legendDiv: null,
      layersPlotados: {},
      overlayLayer: null,
      feicoesExibidas: 0,
      timeoutControl: null
    };
  },

  mounted() {
    this.desenhaMapa();

    // Atualiza o bbox quando o usuário finalizar a movimentação do mapa
    this.map.on('moveend', () => {
      // Cancela o request anterior, caso ele exista
      if (this.timeoutControl) clearTimeout(this.timeoutControl);

      // Espera um pouco antes de fazer novamente a requisição, pois o usuário pode movimentar muito o mapa para encontrar um local
      this.timeoutControl = setTimeout(
        () => (this.bboxString = this.map.getBounds().toBBoxString()),
        2000
      );
    });

    this.plotaLayersMapa();
  },

  computed: {
    totalLayers() {
      return this.layers.length ?? 0;
    },

    totalFeicoes() {
      return this.layers.reduce((acc, layer) => acc + layer.numero_feicoes, 0);
    },

    maximoFeicoes() {
      return this.getMapConfigMaxFeatures ?? 5000;
    },

    percentuaisFeicoesLayers() {
      let percentuais = {};

      this.layers.forEach(({ nome, numero_feicoes }) => {
        percentuais[nome] = numero_feicoes / this.totalFeicoes;
      });

      return percentuais;
    }
  },

  methods: {
    desenhaMapa() {
      this.configuraMapa(3);

      const [longitude, latitude] =
        this.$store.getters.getCompanyCapitalCoordinates;

      this.map.setView([latitude, longitude], 18);
    },

    configuraMapa(zoom) {
      if (this.map) {
        this.map.remove();
      }

      this.map = L.map(this.mapaId, {
        fullscreenControl: true,
        loadingControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });

      this.layerControl = L.control.layers(mapboxConfig.getBaseLayers());
      this.layerControl.addTo(this.map);

      this.createScaleControl(L).addTo(this.map);
      this.createGeocoderControl(L, this.map).addTo(this.map);
      this.createBrowserPrintControl(L, 'Mapa Layers Personalizados').addTo(
        this.map
      );
      this.configDrawControl(L);
    },

    configDrawControl(L) {
      let { drawnItems, drawControl } = this.createDrawControl(L);

      this.map.addLayer(drawnItems);
      this.map.addControl(drawControl);

      this.map.on('draw:created', ({ layer: polygon }) => {
        drawnItems.addLayer(polygon);

        // Efetua o download dos arquivos com as feições dentro do poligono criado
        const arrLatLngs = polygon.getLatLngs();

        this.layers.forEach((layer) =>
          this.downloadCsvFeaturesWithinPolygon(layer, arrLatLngs[0])
        );
      });

      this.map.on('draw:edited', ({ layers: polygons }) => {
        polygons.eachLayer((polygon) => {
          // Efetua o download dos arquivos com as feições dentro do poligono criado
          const arrLatLngs = polygon.getLatLngs();

          this.layers.forEach((layer) =>
            this.downloadCsvFeaturesWithinPolygon(layer, arrLatLngs[0])
          );
        });
      });
    },

    downloadCsvFeaturesWithinPolygon(layer, polygonLatLngs) {
      const {
        geoserver_layer: layerName,
        geoserver_cql_filter: geoserverCqlFilter,
        geoserver_ogc_filter: geoserverOgcFilter
      } = layer;

      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });

      const promise = this.isCqlFilter()
        ? GeoServerService.downloadCsvFeaturesWithinPolygon(
            layerName,
            polygonLatLngs,
            geoserverCqlFilter,
            0 // maxFeatures
          )
        : GeoServerService.downloadCsvFeaturesWithinPolygonUsingOgcFilter(
            layerName,
            polygonLatLngs,
            geoserverOgcFilter,
            0 // maxFeatures
          );

      promise
        .then((res) => {
          const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
          const nomeArquivo = `${layerName}_feicoes_selecionadas_${timestamp}.csv`;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo CSV com as feições selecionadas.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        });
    },

    getDadosLayer(layer, bbox = '') {
      let {
        nome,
        geoserver_layer: geoserverLayer,
        geoserver_fields: geoserverFields,
        geoserver_cql_filter: geoserverCqlFilter,
        geoserver_ogc_filter: geoserverOgcFilter
      } = layer;

      this.map.spin(true);

      return GeoServerService.getDadosLayer(
        geoserverLayer,
        geoserverFields,
        this.isCqlFilter() ? geoserverCqlFilter : geoserverOgcFilter,
        bbox,
        Math.ceil(this.maximoFeicoes * this.percentuaisFeicoesLayers[nome]),
        'application/json', // outputFormat
        this.getFilterType()
      );
    },

    plotaLayersMapa() {
      this.removeLayersMapa();

      this.layers.forEach(async (layerPersonalizado) => {
        const bounds = this.map.getBounds();
        const response = await this.getDadosLayer(
          layerPersonalizado,
          bounds.toBBoxString()
        );
        const geojson = response.data;
        this.plotaLayerMapa(layerPersonalizado, geojson);
      });

      this.criaLegenda();
    },

    removeLayersMapa() {
      this.feicoesExibidas = 0;

      Object.keys(this.layersPlotados).forEach((nomeLayer) => {
        let oldLayer = this.layersPlotados[nomeLayer] ?? false;
        if (oldLayer) {
          this.layerControl.removeLayer(oldLayer);
          this.map.removeLayer(oldLayer);
          delete this.layersPlotados[nomeLayer];
        }
      });

      this.apagaLegenda();
    },

    plotaLayerMapa(layerPersonalizado, geojson) {
      if (!geojson.numberReturned) {
        this.map.spin(false);
        return;
      }

      const {
        tipo_simbologia: tipoSimbologia,
        simbologia_config: simbologiaConfigString,
        opacidade
      } = layerPersonalizado;

      const simbologiaConfig = JSON.parse(simbologiaConfigString);
      const opacity = opacidade / 100;
      const fillOpacity = opacity;
      const weight = this.getMapFeatureWeight;
      let iconColor = null;

      if (tipoSimbologia === 'MAPA_CALOR') {
        return this.plotaMapaCalor(
          layerPersonalizado,
          geojson,
          simbologiaConfig
        );
      }

      let layer = L.geoJSON(geojson, {
        style: (feature) => {
          switch (tipoSimbologia) {
            case 'SIMPLES':
              var color = simbologiaConfig.corFeicoes;
              return {
                color,
                fillColor: color,
                opacity,
                fillOpacity,
                weight
              };

            case 'CATEGORIZADA':
              var { campoParaClassificacao } = simbologiaConfig;
              var dadoParaClassificacao =
                feature.properties[campoParaClassificacao];
              var color = this.getIconeCorFeicaoSimbologiaCategorizada(
                simbologiaConfig,
                dadoParaClassificacao
              ).cor;

              return {
                color,
                fillColor: color,
                opacity,
                fillOpacity,
                weight
              };

            case 'GRADUADA':
              var { campoParaClassificacao } = simbologiaConfig;
              var dadoParaClassificacao =
                feature.properties[campoParaClassificacao];
              var color = this.getIconeCorFeicaoSimbologiaGraduada(
                simbologiaConfig,
                dadoParaClassificacao
              ).cor;

              return {
                color,
                fillColor: color,
                opacity,
                fillOpacity,
                weight
              };

            default:
              return {
                opacity,
                fillOpacity,
                weight
              };
          }
        },

        onEachFeature: (feature, layer) => {
          const msgPopup = this.msgPopup(
            layerPersonalizado,
            feature.properties
          );
          if (msgPopup) {
            layer.bindPopup(msgPopup);
          }
        },

        pointToLayer: (feature, latlng) => {
          switch (tipoSimbologia) {
            case 'SIMPLES':
              iconColor = {
                cor: simbologiaConfig.corFeicoes,
                icone: simbologiaConfig.iconeFeicoes
              };
              break;
            case 'GRADUADA':
              let dadoParaClassificacaoGraduada =
                feature.properties[simbologiaConfig.campoParaClassificacao];
              iconColor = this.getIconeCorFeicaoSimbologiaGraduada(
                simbologiaConfig,
                dadoParaClassificacaoGraduada
              );
              break;
            case 'CATEGORIZADA':
              let dadoParaClassificacaoCategorizada =
                feature.properties[simbologiaConfig.campoParaClassificacao];
              iconColor = this.getIconeCorFeicaoSimbologiaCategorizada(
                simbologiaConfig,
                dadoParaClassificacaoCategorizada
              );
              break;
          }

          if (tipoSimbologia == 'MAPA_CALOR' || !iconColor.icone) {
            return L.circleMarker(latlng, {
              radius: this.getMapFeatureRadius
            });
          }

          return L.marker(latlng, {
            icon: L.icon.glyph({
              iconMDI: iconColor.icone,
              colorBackground: iconColor.cor,
              opacity
            })
          });
        }
      });

      // Se o layer já existe, remove-o
      let oldLayer = this.layersPlotados[layerPersonalizado.nome] ?? false;
      if (oldLayer) {
        this.layerControl.removeLayer(oldLayer);
        this.map.removeLayer(oldLayer);
      }

      // Plota o layer no mapa e no controle
      layer.addTo(this.map);
      this.layerControl.addOverlay(layer, layerPersonalizado.nome);

      this.layersPlotados[layerPersonalizado.nome] = layer;
      this.feicoesExibidas += geojson.numberReturned;

      this.map.spin(false);
    },

    plotaMapaCalor(layerPersonalizado, geojson, simbologiaConfig) {
      this.plotaPontosComPopup(layerPersonalizado, geojson);

      const { raioPontos, desfoquePontos, campoParaClassificacao } =
        simbologiaConfig;

      let max = 1.0;

      const points = geojson.features.map((feature) => {
        let dadoParaClassificacao = feature.properties[campoParaClassificacao];
        let point = feature.geometry;

        if (dadoParaClassificacao > max) {
          max = dadoParaClassificacao;
        }

        return [...point.coordinates.reverse(), dadoParaClassificacao];
      });

      // minOpacity - the minimum opacity the heat will start at
      // maxZoom - zoom level where the points reach maximum intensity (as intensity scales with zoom), equals maxZoom of the map by default
      // max - maximum point intensity, 1.0 by default
      // radius - radius of each "point" of the heatmap, 25 by default
      // blur - amount of blur, 15 by default
      // gradient - color gradient config, e.g. {0.4: 'blue', 0.65: 'lime', 1: 'red'}

      let layer = L.heatLayer(points, {
        maxZoom: 7,
        max,
        radius: raioPontos,
        blur: desfoquePontos
      });

      layer.addTo(this.map);

      this.layersPlotados[layerPersonalizado.nome] = layer;

      this.map.spin(false);
    },

    plotaPontosComPopup(layerPersonalizado, geojson) {
      const color = this.getMapFeatureColor;
      const opacity = this.getMapFeatureOpacity;
      const fillOpacity = this.getMapFeatureFillOpacity;
      const weight = this.getMapFeatureWeight;

      let overlayLayer = L.geoJSON(geojson, {
        style: () => {
          return {
            color,
            fillColor: color,
            opacity,
            fillOpacity,
            weight
          };
        },

        onEachFeature: (feature, layer) => {
          const msgPopup = this.msgPopup(
            layerPersonalizado,
            feature.properties
          );
          if (msgPopup) {
            layer.bindPopup(msgPopup);
          }
        },

        pointToLayer: (feature, latlng) => {
          return L.circleMarker(latlng, {
            radius: this.getMapFeatureRadius
          });
        }
      });

      if (this.overlayLayer) {
        this.map.removeLayer(this.overlayLayer);
        this.layerControl.removeLayer(this.overlayLayer);
      }

      this.overlayLayer = overlayLayer;

      this.layerControl.addOverlay(
        this.overlayLayer,
        `${layerPersonalizado.nome} :: pontos`
      );
    },

    getIconeCorFeicaoSimbologiaCategorizada(
      simbologiaConfig,
      dadoParaClassificacao
    ) {
      const { iconesCoresFeicoes, iconeCorOutrasFeicoes } = simbologiaConfig;

      return (
        iconesCoresFeicoes[
          dadoParaClassificacao !== null ? dadoParaClassificacao.trim() : null
        ] || iconeCorOutrasFeicoes
      );
    },

    getIconeCorFeicaoSimbologiaGraduada(
      simbologiaConfig,
      dadoParaClassificacao
    ) {
      const { iconesCoresFeicoes, iconeCorOutrasFeicoes } = simbologiaConfig;

      let iconeCorFeicoes = iconeCorOutrasFeicoes;

      dadoParaClassificacao = Number(dadoParaClassificacao);

      let infoFeicoes = iconesCoresFeicoes.find((info) => {
        let inicio = Number(info.range[0]);
        let fim = Number(info.range[1]);

        return dadoParaClassificacao >= inicio && dadoParaClassificacao <= fim;
      });

      if (infoFeicoes) {
        iconeCorFeicoes = { icone: infoFeicoes.icone, cor: infoFeicoes.cor };
      }

      return iconeCorFeicoes;
    },

    msgPopup(layerPersonalizado, linha) {
      let msg = `<h4>${layerPersonalizado.nome}</h4>`;

      Object.keys(linha).forEach((campo) => {
        const dado = linha[campo];
        if (campo === 'geom' || dado === '' || dado === null) return;
        msg += `<b>${campo}:</b> ${linha[campo]}<br>`;
      });

      return msg;
    },

    criaLegenda() {
      if (!this.legendControl) {
        this.legendControl = L.control({ position: 'bottomright' });
        this.legendDiv = L.DomUtil.create('div', 'mapa-info mapa-legend');
      }

      let html = [];

      this.layers.forEach(async (layerPersonalizado) => {
        const {
          tipo_simbologia: tipoSimbologia,
          simbologia_config: simbologiaConfigString
        } = layerPersonalizado;

        const simbologiaConfig = JSON.parse(simbologiaConfigString);

        if (tipoSimbologia === 'SIMPLES') {
          html.push(
            this.criaHtmlLegendaSimbologiaSimples(
              layerPersonalizado,
              simbologiaConfig
            )
          );
        }

        if (tipoSimbologia === 'CATEGORIZADA') {
          html.push(
            this.criaHtmlLegendaSimbologiaCategorizada(
              layerPersonalizado,
              simbologiaConfig
            )
          );
        }

        if (tipoSimbologia === 'GRADUADA') {
          html.push(
            this.criaHtmlLegendaSimbologiaGraduada(
              layerPersonalizado,
              simbologiaConfig
            )
          );
        }

        if (tipoSimbologia === 'MAPA_CALOR') {
          html.push(
            this.criaHtmlLegendaSimbologiaMapaCalor(
              layerPersonalizado,
              simbologiaConfig
            )
          );
        }
      });

      if (html.length) {
        this.legendDiv.innerHTML = html.join('<br>');
        this.legendControl.onAdd = () => this.legendDiv;
        this.legendControl.addTo(this.map);
      }
    },

    criaHtmlLegendaSimbologiaSimples(layerPersonalizado, simbologiaConfig) {
      const { corFeicoes, iconeFeicoes } = simbologiaConfig;

      let bgColor1 = iconeFeicoes ? corFeicoes : 'white';
      let bgColor2 = iconeFeicoes ? 'white' : corFeicoes;

      let html = `
      <div style="display:flex; align-items: center; margin: 3px 0px">
        <span style="height: 23px; background-color: ${bgColor1}">
          <div style="display: inline-flex; justify-content: center; height: 23px; width: 23px; background-color: ${bgColor2}; border-radius: 50%; border: 2px solid ${corFeicoes}">
            <i style="margin: auto; font-size: 17px; color: black" class="v-icon notranslate mdi ${iconeFeicoes}"></i>
          </div>
        </span>&nbsp;${layerPersonalizado.nome}
      </div>
    `;

      return html;
    },

    criaHtmlLegendaSimbologiaCategorizada(
      layerPersonalizado,
      simbologiaConfig
    ) {
      const {
        iconesCoresFeicoes,
        iconeCorOutrasFeicoes,
        campoParaClassificacao
      } = simbologiaConfig;

      let html = '';

      if (campoParaClassificacao) {
        html += `<h4>${layerPersonalizado.nome}: ${campoParaClassificacao}</h4>`;
      }

      for (let dado in iconesCoresFeicoes) {
        let infoFeicao = this.getIconeCorFeicaoSimbologiaCategorizada(
          simbologiaConfig,
          dado
        );

        let bgColor1 = infoFeicao.icone ? infoFeicao.cor : 'white';
        let bgColor2 = infoFeicao.icone ? 'white' : infoFeicao.cor;

        html += `
          <div style="display:flex; align-items: center; margin: 3px 0px">
            <span style="height: 23px; background-color: ${bgColor1}">
              <div style="display: inline-flex; justify-content: center; height: 23px; width: 23px; background-color: ${bgColor2}; border-radius: 50%; border: 2px solid ${infoFeicao.cor}">
                <i style="margin: auto; font-size: 17px; color: black" class="v-icon notranslate mdi ${infoFeicao.icone}"></i>
              </div>
            </span>&nbsp;${dado}
          </div>
        `;
      }

      let bgColor1 = iconeCorOutrasFeicoes.icone
        ? iconeCorOutrasFeicoes.cor
        : 'white';
      let bgColor2 = iconeCorOutrasFeicoes.icone
        ? 'white'
        : iconeCorOutrasFeicoes.cor;

      html += `
        <div style="display:flex; align-items: center">
          <span style="background-color: ${bgColor1}">
            <div style="display: inline-flex; justify-content: center; height: 23px; width: 23px; background-color: ${bgColor2}; border-radius: 50%; border: 2px solid ${iconeCorOutrasFeicoes.cor}">
              <i style="margin: auto; font-size: 17px; color: black" class="v-icon notranslate mdi ${iconeCorOutrasFeicoes.icone}"></i>
            </div>
          </span>&nbsp;Outros
        </div>
      `;

      return html;
    },

    criaHtmlLegendaSimbologiaGraduada(layerPersonalizado, simbologiaConfig) {
      const {
        iconesCoresFeicoes,
        iconeCorOutrasFeicoes,
        campoParaClassificacao
      } = simbologiaConfig;

      let html = '';

      if (campoParaClassificacao) {
        html += `<h4>${layerPersonalizado.nome}: ${campoParaClassificacao}</h4>`;
      }

      iconesCoresFeicoes.forEach((infoFeicao) => {
        let [inicio, fim] = infoFeicao.range;

        let bgColor1 = infoFeicao.icone ? infoFeicao.cor : 'white';
        let bgColor2 = infoFeicao.icone ? 'white' : infoFeicao.cor;

        html += `
          <div style="display:flex; align-items: center; margin: 3px 0px">
            <span style="height: 23px; background-color: ${bgColor1}">
              <div style="display: inline-flex; justify-content: center; height: 23px; width: 23px; background-color: ${bgColor2}; border-radius: 50%; border: 2px solid ${infoFeicao.cor}">
                <i style="margin: auto; font-size: 17px; color: black" class="v-icon notranslate mdi ${infoFeicao.icone}"></i>
              </div>
            </span>&nbsp;${inicio} -  ${fim}
          </div>
        `;
      });

      let bgColor1 = iconeCorOutrasFeicoes.icone
        ? iconeCorOutrasFeicoes.cor
        : 'white';
      let bgColor2 = iconeCorOutrasFeicoes.icone
        ? 'white'
        : iconeCorOutrasFeicoes.cor;

      html += `
        <div style="display:flex; align-items: center">
          <span style="background-color: ${bgColor1}">
            <div style="display: inline-flex; justify-content: center; height: 23px; width: 23px; background-color: ${bgColor2}; border-radius: 50%; border: 2px solid ${iconeCorOutrasFeicoes.cor}">
              <i style="margin: auto; font-size: 17px; color: black" class="v-icon notranslate mdi ${iconeCorOutrasFeicoes.icone}"></i>
            </div>
          </span>&nbsp;Outros
        </div>
      `;

      return html;
    },

    criaHtmlLegendaSimbologiaMapaCalor(layerPersonalizado, simbologiaConfig) {
      const { campoParaClassificacao } = simbologiaConfig;

      let html = '';

      if (campoParaClassificacao) {
        html += `<h4>${layerPersonalizado.nome}: ${campoParaClassificacao}</h4>`;
      }

      return html;
    },

    apagaLegenda() {
      if (this.legendDiv) this.legendDiv.innerHTML = '';
    }
  },

  watch: {
    layers() {
      this.plotaLayersMapa();
    },

    bboxString() {
      this.plotaLayersMapa();
    }
  }
};
