// eslint-disable-next-line no-undef
L.Icon.Glyph = L.Icon.extend({
  options: {
    iconMDI: '',
    iconSize: '15px',
    colorBackground: '#c30b82',
    opacity: '1'
  },

  createIcon: function () {
    var div = document.createElement('div');
    div.style.opacity = this.options.opacity;

    div.appendChild(this._createPinMarker());
    div.appendChild(this._createPinMarkerIcon());

    if (this.options.iconMDI) {
      div.appendChild(this._createPinMarkerIcon());
    }

    return div;
  },

  _createPinMarker() {
    // eslint-disable-next-line no-undef
    let span = L.DomUtil.create('span', 'leaflet-marker-pin');
    span.style.backgroundColor = `${this.options.colorBackground}`;

    return span;
  },

  _createPinMarkerIcon() {
    // eslint-disable-next-line no-undef
    var i = L.DomUtil.create(
      'i',
      `v-icon notranslate mdi ${this.options.iconMDI}`
    );
    i.style.fontSize = `${this.options.iconSize}`;
    i.style.position = 'absolute';
    i.style.left = '-10px';
    i.style.top = '-10px';
    return i;
  }
});

// eslint-disable-next-line no-undef
L.icon.glyph = function (options) {
  // eslint-disable-next-line no-undef
  return new L.Icon.Glyph(options);
};
